/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { Component, useContext } from "react";
import { graphql } from "gatsby";
import { AppContext } from "~context/AppContext";
import FullHeight from "~components/FullHeight";
import Layout from "~components/Layout";
import LeaderboardSubscriber from "~components/LeaderboardSubscriber";
import NavClipped from "~components/NavClipped";
import SEO from "~components/SEO";
import Cross from "~components/svg/Cross";
import { getRandomIntByRange, splitStringToParagraphs } from "~utils/helpers";

import paper from "~assets/images/play/paper.gif";
import scissors from "~assets/images/play/scissors.gif";
import stone from "~assets/images/play/stone.gif";

class PlayPageComponent extends Component {
  state = {
    enemy: null,
    idleIndex: null,
    leaderboardActive: false,
    leaderboardInterrupt: false,
    modalActive: true,
    optionRollIndex: null,
    optionRollVelocity: 10,
    result: null,
    score: 0,
    selection: null,
    selectionHover: null,
    streak: null
  };

  intervals = [];

  options = [];

  timeouts = [];

  componentDidMount() {
    const { appContext, frontmatter } = this.props;

    this.options = [frontmatter.paper, frontmatter.stone, frontmatter.scissors];

    appContext.setLanguageWidgetColor(`black`);
    appContext.setNavColor(`black`);
    appContext.setDefaultNavActive(false);

    this.setState({
      optionRollIndex: getRandomIntByRange(0, 2)
    });

    this.intervals.push(
      setInterval(() => {
        this.setState(prevState => ({
          idleIndex: prevState.idleIndex + 1 < 3 ? prevState.idleIndex + 1 : 0
        }));
      }, 1000)
    );
  }

  componentWillUnmount() {
    const { appContext } = this.props;

    this.clearTime();

    appContext.setLanguageWidgetColor(`white`);
    appContext.setNavColor(`white`);
    appContext.setDefaultNavActive(true);
  }

  clearTime = () => {
    this.intervals.forEach(interval => {
      clearTimeout(interval);
    });

    this.timeouts.forEach(timeout => {
      clearTimeout(timeout);
    });
  };

  //

  roll = () => {
    this.timeouts.push(
      setTimeout(() => {
        this.setState(
          prevState => ({
            optionRollIndex:
              prevState.optionRollIndex + 1 < 3
                ? prevState.optionRollIndex + 1
                : 0,
            optionRollVelocity: prevState.optionRollVelocity * 1.15
          }),
          () => {
            if (this.state.optionRollVelocity < 300) {
              this.roll();
            } else {
              this.setState(prevState => ({
                enemy: this.options[prevState.optionRollIndex].toLowerCase()
              }));

              setTimeout(() => {
                this.showResult();
              }, 1000);
            }
          }
        );
      }, this.state.optionRollVelocity)
    );
  };

  play = selection => {
    this.setState({
      selectionHover: selection,
      selection
    });

    this.roll();
  };

  reset = () => {
    this.setState({
      enemy: null,
      idleIndex: null,
      optionRollIndex: getRandomIntByRange(0, 2),
      optionRollVelocity: 10,
      result: null,
      selection: null,
      selectionHover: null
    });
  };

  showResult = () => {
    const { frontmatter } = this.props;
    const { enemy, selection } = this.state;

    let { score, streak } = this.state;

    let result;

    if (selection.toLowerCase() === enemy.toLowerCase()) {
      result = frontmatter.resultDraw;
    } else {
      let userWon = true;

      switch (selection.toLowerCase()) {
        case frontmatter.paper.toLowerCase():
          if (enemy.toLowerCase() === frontmatter.scissors.toLowerCase()) {
            userWon = false;
          }

          break;

        case frontmatter.scissors.toLowerCase():
          if (enemy.toLowerCase() === frontmatter.stone.toLowerCase()) {
            userWon = false;
          }

          break;

        case frontmatter.stone.toLowerCase():
          if (enemy.toLowerCase() === frontmatter.paper.toLowerCase()) {
            userWon = false;
          }

          break;

        default:
          break;
      }

      if (userWon) {
        result = frontmatter.resultWin;
        score += 1;

        if (streak === null) {
          streak = 0;
        }

        streak += 1;
      } else {
        result = frontmatter.resultLoss;
        score -= 1;
        streak = null;
      }
    }

    this.setState({
      leaderboardInterrupt: true
    });

    this.setState(
      {
        result,
        score,
        streak
      },
      () => {
        this.timeouts.push(
          setTimeout(() => {
            this.showLeaderboard();

            this.setState({
              leaderboardInterrupt: false
            });
          }, 1000)
        );
      }
    );
  };

  hideLeaderboard = () => {
    this.setState({
      leaderboardActive: false
    });
  };

  showLeaderboard = () => {
    this.setState({
      leaderboardActive: true
    });
  };

  //

  render() {
    const { frontmatter, location } = this.props;
    const {
      enemy,
      idleIndex,
      leaderboardActive,
      leaderboardInterrupt,
      modalActive,
      optionRollIndex,
      result,
      score,
      selection,
      selectionHover,
      streak
    } = this.state;

    let backgroundColor = `bg-white`;

    if (result) {
      switch (result) {
        case frontmatter.resultWin:
          backgroundColor = `bg-pink`;
          break;

        case frontmatter.resultLoss:
          backgroundColor = `bg-olive`;
          break;

        case frontmatter.resultDraw:
          // backgroundColor = `bg-drab`;
          backgroundColor = `bg-brown`;
          break;

        default:
          break;
      }
    }

    //

    return (
      <>
        <SEO
          customTitle={frontmatter.title}
          customDescription={frontmatter.seoDescription}
          customKeywords={frontmatter.seoKeywords}
          path={location.pathname}
        />

        {/* // */}

        <div
          className={`play-page__leaderboard ${
            leaderboardActive ? `active` : ``
          } w-screen h-screen fixed top-0 right-0 bottom-0 left-0 z-50 flex items-center justify-end`}
        >
          <div
            role="presentation"
            className="w-full h-full absolute top-0 right-0 bottom-0 left-0 bg-black opacity-25"
            onClick={this.hideLeaderboard}
          ></div>

          <div className="play-page__leaderboard__inner h-screen relative z-10 block pt-16 xs:pt-12 px-16 xs:px-6 overflow-y-scroll bg-black text-center text-white">
            <h3 className="b3 text-white">Your score</h3>

            <h2 className="play-page__leaderboard__score mt-4 mb-16 xs:mb-8 text-white">
              {score}
            </h2>

            <LeaderboardSubscriber
              heading={frontmatter.leaderboardHeading}
              onSubmitted={() => {
                setTimeout(() => {
                  this.hideLeaderboard();
                }, 1500);
              }}
            />
          </div>
        </div>

        {/* // */}

        <div
          className={`play-page__modal ${
            modalActive ? `active` : ``
          } w-screen h-screen fixed top-0 right-0 bottom-0 left-0 z-50 flex items-center justify-center`}
        >
          <div className="w-full h-full absolute top-0 right-0 bottom-0 left-0 bg-black opacity-25"></div>

          <article className="play-page__modal__inner animation-appear animation-delay-2 w-2/3 xs:w-full xs:mx-4 relative flex flex-col items-center justify-center xs:justify-between pt-12 xs:pt-6 pb-12 xs:pb-6 bg-white">
            <button
              type="button"
              className="absolute top-0 right-0"
              onClick={() => {
                this.setState({
                  modalActive: false
                });
              }}
            >
              <Cross
                className="w-12 h-12 mt-3 xs:mt-0 mr-6 xs:mr-0 p-2 absolute top-0 right-0 cursor-pointer"
                color="black"
              />
            </button>

            <h3 className="xs:px-12 f5 xs:text-center">
              {frontmatter.modalHeading}
            </h3>

            <button
              type="button"
              className="w-full relative my-24 xs:my-0 xs:mb-4 xs:px-4 f1 text-center"
              onClick={() =>
                this.setState({
                  modalActive: false
                })
              }
            >
              {frontmatter.modalButtonText}
            </button>

            <div className="w-1/2 xs:w-full relative b3 text-center">
              {splitStringToParagraphs(
                frontmatter.modalCaveat,
                `b3 text-center`
              )}
            </div>
          </article>
        </div>

        {/* // */}

        <Layout
          className={`play-page transition-background w-full h-screen relative flex items-center justify-center overflow-x-hidden ${backgroundColor}`}
          location={location}
        >
          <NavClipped color="black" />

          {streak !== null && (
            <div className="play-page__streak absolute top-0 right-0 pointer-events-none pt-16 pr-12 text-center">
              <h4 className="mt-2 b3">Winning streak</h4>
              <h2 className="f3">{streak}</h2>
            </div>
          )}

          <FullHeight>
            <section
              className={`play-page__images transition-transform ${
                selection !== null ? `selected` : ``
              } w-full h-full absolute top-0 right-0 bottom-0 left-0 flex items-center pointer-events-none`}
            >
              {(selectionHover === frontmatter.paper.toLowerCase() ||
                (!selectionHover && idleIndex === 0)) && (
                <div className="play-page__image play-page__image--paper absolute block inverted">
                  <img
                    className="animation-pop w-full relative block"
                    src={paper}
                    alt="Paper"
                  />
                </div>
              )}

              {(selectionHover === frontmatter.stone.toLowerCase() ||
                (!selectionHover && idleIndex === 1)) && (
                <div className="play-page__image play-page__image--stone absolute block">
                  <img
                    className="animation-pop w-full relative block"
                    src={stone}
                    alt="Stone"
                  />
                </div>
              )}

              {(selectionHover === frontmatter.scissors.toLowerCase() ||
                (!selectionHover && idleIndex === 2)) && (
                <div className="play-page__image play-page__image--scissors absolute block inverted">
                  <img
                    className="animation-pop w-full relative block"
                    src={scissors}
                    alt="Scissors"
                  />
                </div>
              )}

              {/* // */}

              {enemy && enemy === frontmatter.paper.toLowerCase() && (
                <div className="play-page__image play-page__image--enemy play-page__image--paper absolute block right-0 inverted">
                  <img
                    className="animation-pop w-full relative block"
                    src={paper}
                    alt="Paper"
                  />
                </div>
              )}

              {enemy && enemy === frontmatter.stone.toLowerCase() && (
                <div className="play-page__image play-page__image--enemy play-page__image--stone absolute block right-0">
                  <img
                    className="animation-pop w-full relative block"
                    src={stone}
                    alt="Stone"
                  />
                </div>
              )}

              {enemy && enemy === frontmatter.scissors.toLowerCase() && (
                <div className="play-page__image play-page__image--enemy play-page__image--scissors absolute block right-0 inverted">
                  <img
                    className="animation-pop w-full relative block"
                    src={scissors}
                    alt="Scissors"
                  />
                </div>
              )}
            </section>

            {(!selection && (
              <section className="h-full grid">
                <ul className="play-page__links grid-end-22 grid-start-3 h-full relative flex flex-col justify-center">
                  <li className="play-page__link relative">
                    <button
                      type="button"
                      className="play-page__megatext transition-opacity w-full py-2 f1 text-left uppercase"
                      onClick={() => this.play(frontmatter.paper.toLowerCase())}
                      onMouseEnter={() =>
                        this.setState({
                          selectionHover: frontmatter.paper.toLowerCase()
                        })
                      }
                      onMouseLeave={() =>
                        this.setState({ selectionHover: null })
                      }
                    >
                      {frontmatter.paper}
                    </button>
                  </li>

                  <li className="play-page__link relative">
                    <button
                      type="button"
                      className="play-page__megatext transition-opacity w-full py-2 f1 text-left uppercase"
                      onClick={() => this.play(frontmatter.stone.toLowerCase())}
                      onMouseEnter={() =>
                        this.setState({
                          selectionHover: frontmatter.stone.toLowerCase()
                        })
                      }
                      onMouseLeave={() =>
                        this.setState({ selectionHover: null })
                      }
                    >
                      {frontmatter.stone}
                    </button>
                  </li>

                  <li className="play-page__link relative">
                    <button
                      type="button"
                      className="play-page__megatext transition-opacity w-full py-2 f1 text-left uppercase"
                      onClick={() =>
                        this.play(frontmatter.scissors.toLowerCase())
                      }
                      onMouseEnter={() =>
                        this.setState({
                          selectionHover: frontmatter.scissors.toLowerCase()
                        })
                      }
                      onMouseLeave={() =>
                        this.setState({ selectionHover: null })
                      }
                    >
                      {frontmatter.scissors}
                    </button>
                  </li>
                </ul>
              </section>
            )) || (
              <>
                {(result === null && (
                  <>
                    <div className="w-full h-full absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center">
                      <h5 className="f4">VS</h5>
                    </div>

                    <section className="h-full grid">
                      <div className="grid-end-22 grid-start-3 h-full relative flex flex-col justify-center">
                        <h2 className="play-page__megatext animation-appear w-full py-2 f1 text-left uppercase">
                          {selection}
                        </h2>

                        <h2 className="play-page__megatext opacity-0 py-2 f1 text-left uppercase">
                          .
                        </h2>

                        <h2 className="play-page__megatext animation-appear py-2 f1 text-right uppercase">
                          {(enemy && <span>{enemy}</span>) || (
                            <span>{this.options[optionRollIndex]}</span>
                          )}
                        </h2>
                      </div>
                    </section>
                  </>
                )) || (
                  <div className="w-full h-full absolute top-0 right-0 bottom-0 left-0 flex flex-col items-center justify-center">
                    <h5 className="play-page__megatext animation-scale f1 text-white">
                      {result}
                    </h5>

                    <button
                      type="button"
                      className={`${
                        leaderboardInterrupt
                          ? `opacity-0 pointer-events-none`
                          : `animation-appear`
                      } mt-12 f5 text-white`}
                      onClick={this.reset}
                    >
                      {frontmatter.replay}
                    </button>
                  </div>
                )}
              </>
            )}
          </FullHeight>
        </Layout>
      </>
    );
  }
}

//

const PlayPage = ({ data, location }) => {
  const appContext = useContext(AppContext);
  const { frontmatter } = data.markdownRemark;

  return (
    <PlayPageComponent
      appContext={appContext}
      frontmatter={frontmatter}
      location={location}
    />
  );
};

export default PlayPage;

export const query = graphql`
  query PlayPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        paper
        stone
        scissors

        paperImage {
          publicURL
        }

        stoneImage {
          publicURL
        }

        scissorsImage {
          publicURL
        }

        replay
        resultDraw
        resultLoss
        resultWin

        modalHeading
        modalButtonText
        modalCaveat

        leaderboardHeading
        seoDescription
        seoKeywords
      }
    }
  }
`;
