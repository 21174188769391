/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { Component, useContext } from "react";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import { fancyError, fancyLog } from "~utils/helpers";

class LeaderboardSubscriberComponent extends Component {
  state = {
    focused: ``,
    form: {},
    submitting: false,
    submitted: false
  };

  //

  onBlur = () => {
    this.setState({
      focused: ``
    });
  };

  onChange = e => {
    const { form } = this.state;
    const { name, value } = e.currentTarget;

    if (!form[name]) {
      form[name] = {
        value: ``
      };
    }

    form[name].value = value;

    this.setState({
      form
    });
  };

  onFocus = e => {
    const focused = e.currentTarget.name;

    this.setState({
      focused
    });
  };

  onSubmit = e => {
    e.preventDefault();

    this.setState({
      submitting: true
    });

    if (
      typeof window !== `undefined` &&
      window.location.pathname.includes(`localhost`)
    ) {
      if (this?.props?.onSubmitted) {
        this.props.onSubmitted();
      }

      setTimeout(() => {
        this.setState({
          submitting: false,
          submitted: true
        });
      }, 3000);

      return false;
    }

    const { form } = this.state;

    const mailchimpData = {
      email: form.email.value
    };

    fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS}/mailchimp`, {
      body: JSON.stringify(mailchimpData),
      method: `POST`
    })
      .then(() => {
        fancyLog(`Mailchimp Complete`);

        if (this?.props?.onSubmitted) {
          this.props.onSubmitted();
        }

        setTimeout(() => {
          this.setState({
            submitting: false,
            submitted: true
          });
        }, 300);
      })
      .catch(error => {
        fancyError(error);
      });

    return false;
  };

  //

  render() {
    const { focused, submitted, submitting } = this.state;

    let { heading } = this.props;

    if (!heading) {
      heading = `You’ll hear from us roughly once a month with news from the studio, our latest work, job opportunities and upcoming events. It’s an easy way to find out what we’re up to.`;
    }

    let buttonText = `Submit`;

    if (submitting) {
      buttonText = `Submitting...`;
    } else if (submitted) {
      buttonText = `Thanks!`;
    }

    return (
      <div
        className={`leaderboard-subscriber ${
          submitted || submitting ? `opacity-50 pointer-events-none` : ``
        } w-full relative xs:pt-8 pb-32 xs:pb-8 text-white`}
      >
        <form className="w-full relative" onSubmit={this.onSubmit}>
          <label
            htmlFor="name"
            className={`newsletter__name ${
              focused === `name` ? `focused` : ``
            } ${
              submitting || submitted ? `opacity-25 pointer-events-none` : ``
            } w-full relative mt-16 xs:mt-8`}
          >
            <input
              className="w-full relative block mt-4 py-4 px-2 b2"
              name="name"
              placeholder={focused === `name` ? `` : `Your name*`}
              readOnly={submitted || submitting}
              required
              onBlur={this.onBlur}
              onChange={this.onChange}
              onFocus={this.onFocus}
              type="text"
            />
          </label>

          <label
            htmlFor="email"
            className={`newsletter__email ${
              focused === `email` ? `focused` : ``
            } ${
              submitting || submitted ? `opacity-25 pointer-events-none` : ``
            } relative mt-16 xs:mt-8`}
          >
            <input
              className="w-full relative block mt-4 py-4 px-2 b2"
              name="email"
              placeholder={focused === `email` ? `` : `Email*`}
              readOnly={submitted || submitting}
              required
              onBlur={this.onBlur}
              onChange={this.onChange}
              onFocus={this.onFocus}
              type="email"
            />
          </label>

          <p className="my-8 b2 text-left">{heading}</p>

          <div className="animation-appear-down relative flex items-center justify-between mt-2">
            <input
              className="relative block py-2 cursor-pointer hover-underline b2 text-white"
              value={buttonText}
              type="submit"
            />
          </div>
        </form>
      </div>
    );
  }
}

const LeaderboardSubscriber = ({ heading, onSubmitted }) => {
  const appContext = useContext(AppContext);
  const documentContext = useContext(DocumentContext);

  return (
    <LeaderboardSubscriberComponent
      appContext={appContext}
      documentContext={documentContext}
      heading={heading}
      onSubmitted={onSubmitted}
    />
  );
};

export default LeaderboardSubscriber;
